import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Container } from '../components/container'
import { Paper } from '../components/paper'
import { Stack } from '../components/stack'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import LaunchIcon from '@material-ui/icons/Launch'
import CategoryIcon from '@material-ui/icons/Category'
import PhoneIcon from '@material-ui/icons/PhoneIphone'
import PriceIcon from '@material-ui/icons/LocalOffer'
import Button from '@material-ui/core/Button'
//@ts-ignore
import { createCategoryPath } from '../../shared/shared'
import SEO from '../components/seo'

const platformToDisplayName: Record<string, string> = {
  web: 'Web',
  android: 'Android',
  ios: 'iOS',
  desktop: 'Számítógép',
}

const Technology: React.FC<Props> = props => {
  const technology = props.data.api.technology
  const styles = useStyles()

  return (
    <Layout mainProps={{ className: styles.main }}>
      <SEO title={technology.name}></SEO>
      <Container className={styles.container}>
        <TechnologyDetail technology={technology}></TechnologyDetail>
      </Container>
    </Layout>
  )
}

const TechnologyDetail: React.FC<{ technology: Technology }> = ({ technology }) => {
  const styles = useStyles()
  const theme = useTheme()

  return (
    <>
      <Paper className={styles.paper} component="article">
        <header className={styles.header}>
          {/* <img
            className={styles.mainImage}
            src={technology.localImage.childImageSharp.fixed.src}
            width={technology.localImage.childImageSharp.fixed.width}
            height={technology.localImage.childImageSharp.fixed.height}
            alt=""
          /> */}
          <Stack spacing={3} className={styles.headerInner}>
            <div>
              <h2>{technology.name}</h2>
            </div>
            <div className={styles.metaWrap}>
              <CategoryIcon />
              <ul className={styles.grid}>
                {technology.categories.map(category => (
                  <li
                    className={styles.categoryTag}
                    key={category.name}
                    style={{
                      backgroundColor: category.color,
                      color: theme.palette.getContrastText(category.color),
                    }}
                  >
                    <Link to={createCategoryPath(category.slug)}>{category.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            {technology.platforms && technology.platforms.length > 0 && (
              <div className={styles.metaWrap}>
                <PhoneIcon />
                <ul className={styles.grid}>
                  {technology.platforms.map((platform, i) => (
                    <li className={styles.tag} key={i}>
                      {platformToDisplayName[platform] ?? platform}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {technology.isFree && (
              <div className={styles.metaWrap}>
                <PriceIcon />
                <span className={styles.tag}>Ingyenes</span>
              </div>
            )}
            {technology.url && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  href={technology.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<LaunchIcon />}
                >
                  Megnyitás
                </Button>
              </div>
            )}
          </Stack>
        </header>
        <ReactMarkdown
          className={styles.description}
          source={technology.description}
        ></ReactMarkdown>
      </Paper>
    </>
  )
}

const BREAK = 600

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '85ch',
    [`@media (max-width: ${BREAK}px)`]: {
      padding: 0,
    },
  },
  main: {
    [`@media (max-width: ${BREAK}px)`]: {
      padding: 0,
    },
  },
  paper: {
    padding: 0,
  },
  backButton: {
    marginBottom: theme.spacing(3),
  },
  header: {
    backgroundColor: theme.palette.grey[100],
    // padding: `${theme.spacing(8)} ${theme.spacing(7)}`,
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    // [`@media (min-width: ${BREAK}px)`]: {
    //   display: 'grid',
    //   gridTemplateColumns: '1fr 3fr',
    //   gridGap: theme.spacing(5),
    // },
    '& h2': {
      color: theme.palette.text.secondary,
      fontWeight: 400,
    },
  },
  headerInner: {
    padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
    maxWidth: '70ch',
    margin: '0 auto',
  },
  mainImage: {
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.grey[300],
    [`@media (min-width: ${350}px)`]: {
      maxWidth: '50%',
    },
    [`@media (min-width: ${BREAK}px)`]: {
      maxWidth: '100%',
      marginBottom: 0,
    },
    borderRadius: theme.shape.borderRadius,
  },
  description: {
    lineHeight: 1.8,
    padding: `${theme.spacing(5)} ${theme.spacing(4)}`,
    maxWidth: '70ch',
    margin: '0 auto',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    '& p, ul, ol': {
      listStylePosition: 'inside',
    },
    '& ul, ol': {
      paddingLeft: theme.spacing(3),
    },
  },
  categoryTag: {
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.9em',
    '& > a': {
      display: 'block',
      color: 'inherit',
      padding: theme.spacing(1.5),
      textDecoration: 'none',
    },
  },
  grid: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  tag: {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.9em',
    backgroundColor: '#e4e4e4',
  },
  metaWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    '& > svg': {
      color: '#4b4646',
      backgroundColor: '#fafafa',
      borderRadius: '50%',
      padding: 5,
      fontSize: '1.7em',
      filter: 'drop-shadow(0 3px 0 rgba(0,0,0,0.1))',
    },
  },
}))

export interface FixedImage {
  childImageSharp: {
    fixed: {
      src: string
      width: number
      height: number
    }
  }
}

interface Technology {
  _id: string
  name: string
  description: string
  localImage: FixedImage
  url?: string
  platforms?: string[]
  isFree?: boolean
  categories: {
    name: string
    slug: string
    color: string
  }[]
}

interface Props {
  data: {
    api: {
      technology: Technology
    }
  }
}

export const query = graphql`
  query TechnologyQuery($id: String!) {
    api {
      technology(id: $id) {
        _id
        name
        description
        image
        platforms
        isFree
        localImage {
          childImageSharp {
            fixed(width: 256) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        url
        categories {
          name
          slug
          color
        }
      }
    }
  }
`

export default Technology
